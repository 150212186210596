import React from "react";
import GeoChat from "./geochat/geochat";
import { Link } from "react-router-dom";

class LandingPage extends React.Component {
    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        return (
            <div className="splashScreen-landing">
                {/* <div className="geochat">
                <GeoChat/>
            </div> */}
                <div className="splashScreen-info">
                    In order to maintain a space in which all individuals feel
                    safe and welcome, visitors are required to sign in on entry.
                    Your email address will not be made public. This website
                    stores data such as cookies to enable important site
                    functionality including analytics, targeting, and
                    personalisation. <a href="./privacy">Data storage policy</a>
                    .
                </div>
                <div className="splashScreen-name">
                    <img src="./images/header_banner.png" />
                </div>
                <div className="auth">
                    <a className="auth-title auth-3d" onClick={this.props.handleOnClick}>
                        Enter NEXT World 3D<br></br>
                        (recommended)
                    </a>
                    <a className="auth-title" onClick={this.props.handleOnClickAccesible}>
                        Watch the festival on a 2D screen   
                    </a>
                </div>
                <div className="splashScreen-help">
                For optimal experience watch on a desktop computer with latest Chrome or Firefox<br></br>
                In case of a trouble contact help@nextfestival.sk
                </div>

                {/* <div className="splashScreen-image">
                    <img src="./images/splashscreen.jpg" />
                </div> */}
            </div>
        );
    }
}

export default LandingPage;
