import React , { Suspense }from 'react'
import Close from './CloseButton'


class About extends React.Component {
  
    handleClick () {
      this.props.setParentState({showAbout: false})
    }
  
 
    render () {
      return (
        <div className="about">
          <Close handleClick={this.handleClick.bind(this)} />
          <div className="about-col">
            <a className="about-main-link" href="https://nextfestival.sk/" target="_blank">NEXT 2020</a><br></br>
            <a className="about-main-link" href="https://nextfestival.sk/2020/programme/" target="_blank">Full Programme</a><br></br>
            <br></br>
            <div>ABOUT</div>
            <div>
            Founded 2000 in Bratislava, the NEXT Festival is an annual celebration of exploratory music and sonic art: from electronic experiments and free­form improvisation to audio­visual projects, it brings together artists who push the boundaries.
            </div>
            <div>
            NEXT Festival presents and supports adventurous approaches in music of our time, be it improvised or contemporary classical music, avantgarde jazz, noise, ambient, experimental electronica, audiovisual performances, sonic art, or conceptual projects.
            </div>
            <div>
            The main organiser of the festival is Atrakt Art NGO, together with A4 – Space for Contemporary Culture.
            </div>
            <div><br></br>
            <b><a href="https://therodina.com/">The Rodina</a> and <a href="https://handiii.kim/">Handi Kim</a><br /></b>
            3D Web Programming and Design            
            </div>
            <div className="small">
            Commissioned by A4 and Sonic Acts as part of the international collaborative project Re-Imagine Europe, co-funded by the Creative Europe Programme of the European Union, and supported using public funding by the Slovak Arts Council.
            </div>
          </div>  
        </div>
      )
    }
  }
  
  
  export default About