import React from "react";
import ReactPlayer from "react-player";
import Close from './CloseButton'
// import Chat from "./chat/Chat";

class LiveStream extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: null,
        };
    }
    

    componentDidMount() {
        this.stream = this.props.firebase.database().ref("stream/" + 'livestream');
        this.listener = this.stream.on("value", (snapshot) => {
            const url = snapshot.val(); 
            this.setState({url: url})
            console.log(url)
        })

    }

    componentWillUnmount() {
        this.stream.off("value",this.listener)
      }

    handleClick() {
        this.props.history.back()
    }  

    render() {
        return (
            <div className="livestream">
                <Close handleClick={this.handleClick.bind(this)} />
                {/* <Chat /> */}
                <div className="livestream-title">NEXT 2020<br />21st Advanced Music Festival</div>
                <div className="livestream-container">
                    <ReactPlayer
                        className="react-player"
                        width="100%"
                        height="100%"
                        url={this.state.url}
                        playing={true}
                        controls={true}
                    />
                </div>
            </div>
        );
    }
}

export default LiveStream;
