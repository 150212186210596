import React, { Suspense } from "react";
import { Link } from "react-router-dom";
// const Status = React.lazy(() => import('./Status'));

class Header extends React.Component {
    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        var lastURLSegment = this.props.url;

        return (
            <div>
                <div className="header">
                    {this.props.rooms.lobby.open ? (
                        <Link
                            className={IsActive("/lobby", lastURLSegment)}
                            to="/lobby"
                        >
                            <RoomName name="LOBBY" isLive={this.props.rooms.lobby.isLive}/>
                        </Link>
                    ) : (
                        <div className="nav-button-closed">LOBBY</div>
                    )}
                    {this.props.rooms.firststage.open ? (
                        <Link
                            className={IsActive("/firststage", lastURLSegment)}
                            to="/firststage"
                        >
                            <RoomName name="MAIN STAGE" isLive={this.props.rooms.firststage.isLive}/>
                        </Link>
                    ) : (
                        <div className="nav-button-closed">MAIN STAGE</div>
                    )}
                    {this.props.rooms.secondstage.open ? (
                        <Link
                            className={IsActive("/secondstage", lastURLSegment)}
                            to="/secondstage"
                        >
                            <RoomName name="CLUB" isLive={this.props.rooms.secondstage.isLive}/>
                        </Link>
                    ) : (
                        <div className="nav-button-closed">CLUB</div>
                    )}
                    {this.props.rooms.thirdstage.open ? (
                        <Link
                            className={IsActive("/thirdstage", lastURLSegment)}
                            to="/thirdstage"
                        >
                            <RoomName name="AUDITORIUM" isLive={this.props.rooms.thirdstage.isLive}/>
                        </Link>
                    ) : (
                        <div className="nav-button-closed">AUDITORIUM</div>
                    )}
                    {this.props.rooms.instalation.open ? (
                        <Link
                            className={IsActive("/instalation", lastURLSegment)}
                            to="/instalation"
                        >
                            <RoomName name="WAYFINDING" isLive={this.props.rooms.instalation.isLive} />                            
                        </Link>
                    ) : (
                        <div className="nav-button-closed">WAYFINDING</div>
                    )}
                    <div className="flashmessage-container">
                        { typeof(this.props.flash) == 'string' && (
                            <div className="flashmessage">{this.props.flash}</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;

function IsActive(name, url) {
    if (name === url) return "nav-button nav-button-is-active";
    else return "nav-button";
}

function RoomName(props) {
    return (
        <div>
            {props.name}
            {props.isLive ? <span className="nav-is-live"></span> : ''}
        </div>
    )
}
