import React from 'react'
import { Link } from "react-router-dom"


class Chat extends React.Component {

  constructor(props) {
        super(props)
    }
  
    componentDidMount () {
    }
  
    componentWillUnmount () {
    }

    render () {
      return (
        <div className="chat">
          {/* <iframe src="https://titanembeds.com/embed/775306342755074078?css=186" height="100%" width="300" frameBorder="0"></iframe> */}
          <iframe src="https://titanembeds.com/embed/760970677091827783?css=186" height="100%" width="300" frameBorder="0"></iframe>
        </div>
      )
    }
  }
  
export default Chat