import React from 'react'


class MagicButton extends React.Component {
  
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
        <div className="magic-button">
            <iframe key={'test'} src="https://singlebutton.herokuapp.com/" height="100%" width="100%" frameBorder="0"></iframe>
        </div>
      )
    }
  }
  
  
  export default MagicButton