import React, { Suspense, useState } from "react";
import Loading from "../Loading";
import Instructions from "../Instructions";

const BabylonScene = React.lazy(() => import("../BabylonScene.jsx"));

const Installation = function (props) {

    const [text, setText] = useState('');

    return (
        <Suspense fallback={<Loading />}>
            <BabylonScene
                getVolume={props.getVolume}
                firebase={props.firebase}
                sceneFile={props.sceneFile}
                roomName="NextInstalation"
                user={props.user}
                history={props.history}
                handleInstructions={props.handleInstructions}
                streamName="instalation"
                setText={setText}

            />
            {props.displayInstructions && <Instructions />}
            
            { text == 'text1' && (
                <div className="installationOverlay">
                    Wayfinding is an inquiry into the transmogrification of banal daily visual and auditory
                    experiences. It consists of de- and reconstructed footages of wide-open space, grass, rock,
                    and sky, recordings of steps, wind, and trot framed in a labyrinth.<br></br>
                    With delving into such an environment, the participation in one’s own estrangement from
                    one’s actual surroundings and the ingestion in the virtual is being aimed at. At this point
                    deterritorialization is inevitable: the detachment is only amplified by the task to find one’s
                    way through the labyrinth.
                </div>
            )}
            { text == 'text2' && (
                <div className="installationOverlay">
                    No hard feelings, but shouldn’t the machine be here? What about the promise of them
                    finding the way for us? Why do we still play the role of the “mighty mouse”, when a maze-
                    solving machine was conceived somewhat seventy years ago?<br/>
                    Claude Shannon, grand master of information theory created the first artificial learning
                    device, and naturally named the machine mouse in the maze Theseus, after the Greek hero
                    that found his way through the labyrinth of the Minos, the king of Crete. Centuries pass, and
                    yet myth only turns into science that turns into fiction. Actuality apparently transfigures into
                    potentiality, ending up in an abyss of virtuality.
                </div>
            )}
            { text == 'text3' && (
                <div className="installationOverlay">
                    Virtual and actual might coexist in an eventually unexpected manner, the dichotomy of
                    presence and absence just melts down, when the generated labyrinth and the
                    representation of the “real” clashes. In the midst of the clash; mere avatars, absorbed in
                    orientation.<br></br>
                    The act of wayfinding shall finish when the destination is recognized. One might need to fly
                    above to decide whether it is worth to engage with the labyrinth, or just cruise idly and
                    indifferently over it. Rather a bird than a machine-mouse, better dissociated than
                    intertwined, sort of immersed but somewhat neglectful: either way, actions here remain
                    without the agency of matter.
                </div>
            )}
        </Suspense>
    );
};

export default Installation;
