import React, { Suspense } from "react";

import {
    Router as Router,
    Switch,
    Route,
    Link,
    Redirect,
} from "react-router-dom";

import { createBrowserHistory } from "history";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

import LandingPage from "./LandingPage";
import MenuButton from "./MenuButton";
import OverlayButton from "./OverlayButton";
import Header from "./Header";
import Footer from "./Footer";
import Profile from "./Profile";
import About from "./About";
import Privacy from "./Privacy";
import Chat from "./chat/Chat";
import MagicButton from "./MagicButton";

import Lobby from "./scenes/Lobby";
import FirstStage from "./scenes/FirstStage";
import SecondStage from "./scenes/SecondStage";
import ThirdStage from "./scenes/ThirdStage";
import Instalation from "./scenes/Instalation";
import Livestream from "./LiveStream";

import {
    uniqueNamesGenerator,
    adjectives,
    names,
} from "unique-names-generator";

const config = {
    apiKey: "AIzaSyCmSD90yeEwUCRAGg_NafXWi1PBZdS4__M",
    authDomain: "sos-elephant.firebaseapp.com",
    databaseURL: "https://next-2020-24e8d.firebaseio.com/",
    storageBucket: "gs://next-2020-24e8d.appspot.com",
};

firebase.initializeApp(config);

const history = createBrowserHistory();

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            volume: 1,
            login: true,
            rooms: {
                lobby: { message: "", open: false, isLive: false },
                firststage: { message: "", open: false, isLive: true },
                secondstage: { message: "", open: false, isLive: false },
                thirdstage: { message: "", open: false, isLive: false },
                instalation: { message: "", open: false, isLive: false },
                // lobby: { message: "", open: true, isLive: false },
                // firststage: { message: "", open: true, isLive: true },
                // secondstage: { message: "", open: true, isLive: false },
                // thirdstage: { message: "", open: true, isLive: false },
                // instalation: { message: "", open: true, isLive: false },
            },
            displayInstructions: false,
            flash: "",
            chat: true,
            showAbout: false,
            magicButton: false,
            url: "",
        };

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    user: user,
                });

                if (user.displayName === null) {
                    const randomName = uniqueNamesGenerator({
                        dictionaries: [adjectives, names],
                        separator: " ",
                        style: "capital",
                    });
                    user.updateProfile({
                        displayName: randomName,
                    });
                    this.handleUserUpdate();
                }

                let dbUser = firebase
                    .database()
                    .ref("users/" + firebase.auth().currentUser.uid);
                dbUser.set({
                    username: firebase.auth().currentUser.displayName,
                });
                dbUser.onDisconnect().remove();
            } else {
            }
        });

        this.rooms = firebase.database().ref("rooms/");
        this.rooms.on("value", (snapshot) => {
            this.setState({ rooms: snapshot.val() });
        });

        this.flash = firebase.database().ref("flash/");
        this.flash.on("value", (snapshot) => {
            this.setState({ flash: snapshot.val().message });
        });

        this.magicButton = firebase.database().ref("magicbutton/");
        this.magicButton.on("value", (snapshot) => {
            this.setState({ magicButton: snapshot.val().message });
        });

        history.listen((location, action) => {
            this.setState({ url: location.location.pathname });
        });
    }

    uiConfig = {
        signInFlow: "popup",
        credentialHelper: "none",
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: (result) => {},
        },
    };

    handleInstructions = () => {
        this.setState({ displayInstructions: false });
    };

    handleMute = () => {
        this.state.volume === 0
            ? this.setState({ volume: 1 })
            : this.setState({ volume: 0 });
    };

    handleChatToggle = () => {
        this.state.chat === false
            ? this.setState({ chat: true })
            : this.setState({ chat: false });
    }

    enterTheApp = () => {
        if (this.state.user) {
            this.setState({ login: true });
            history.push("/lobby");
        } else {
            firebase
                .auth()
                .signInAnonymously()
                .then((user) => {
                    this.setState({
                        login: true,
                        user: user
                    });
                    history.push("/lobby");
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                });
        }
    };

    enterTheAccesibleApp = () => {
        if (this.state.user) {
            this.setState({ login: true });
            history.push("/livestream");
        } else {
            firebase
                .auth()
                .signInAnonymously()
                .then((user) => {
                    this.setState({
                        login: true,
                    });
                    history.push("/livestream");
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                });
        }
    };

    handleUserUpdate = () => {
        this.state.user.reload().then((updatedUser) => {
            this.setState({
                user: firebase.auth().currentUser,
            });
        });
    };

    getVolume = () => {
        return this.state;
    };

    render() {
        return (
            <Router history={history}>
                <div className="main-container">
                    <div className="header-container">
                        {this.state.user && this.state.login ? (
                            <div>
                                <Header
                                    volume={this.state.volume}
                                    handleMute={this.handleMute}
                                    rooms={this.state.rooms}
                                    url={this.state.url}
                                    flash={this.state.flash}

                                />
                                {this.state.chat ? <Chat /> : ""}
                                {this.state.magicButton && history.location.pathname === '/firststage'  ? <MagicButton /> : ""}
                                <Footer
                                    volume={this.state.volume}
                                    handleMute={this.handleMute}
                                    handleChatToggle={this.handleChatToggle}
                                    rooms={this.state.rooms}
                                    chat={this.state.chat}
                                    user={this.state.user}
                                    logOut={() => {
                                        firebase.auth().signOut();
                                        this.setState({ user: null });
                                        this.setState({ login: false });
                                    }}
                                />
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>

                    <MenuButton
                        handleClick={() => this.setState({ showAbout: true })}
                    />
                    <OverlayButton
                        history={history}
                    />
                    {this.state.showAbout ? (
                        <About setParentState={this.setState.bind(this)} />
                    ) : null}

                    <Switch location={location}>
                        <Route path="/livestream">
                            <Livestream firebase={firebase} history={history} />
                        </Route>
                        <PrivateRoute
                            path="/lobby"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <Lobby
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="lobby.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                            />
                        </PrivateRoute>
                        <PrivateRoute
                            path="/firststage"
                            user={this.state.user}
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <FirstStage
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="club_B.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                            />
                        </PrivateRoute>
                        <PrivateRoute
                            path="/secondstage"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <SecondStage
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="next_C_.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                            />
                        </PrivateRoute>
                        <PrivateRoute
                            path="/thirdstage"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <ThirdStage
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="next_d.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                            />
                        </PrivateRoute>
                        <PrivateRoute
                            path="/instalation"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <Instalation
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="next_andras_1.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                            />
                        </PrivateRoute>
                        <PrivateRoute
                            path="/profile"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <Profile
                                firebase={firebase}
                                user={this.state.user}
                                handleUserUpdate={this.handleUserUpdate}
                                history={history}
                            />
                        </PrivateRoute>

                        <Route path="/privacy">
                            <Privacy />
                        </Route>
                        <Route path="/">
                            <LandingPage
                                handleOnClick={this.enterTheApp}
                                handleOnClickAccesible={
                                    this.enterTheAccesibleApp
                                }
                            />
                        </Route>
                    </Switch>
                </div>
            </Router>
        );
    }
}

function PrivateRoute(props) {
    return (
        <Route
            render={({ location }) =>
                props.user && props.login ? (
                    props.children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export default App;
